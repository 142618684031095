import React  from "react"
import AdminLayout from './admin-layout';

class DashbordComponent extends React.Component {

  render() {
    return (
      <AdminLayout>
          <h5>Dashboard</h5>
      </AdminLayout>
    )
  }
}

export default DashbordComponent
