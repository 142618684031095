import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Dropdown from "react-bootstrap/Dropdown"
import withLocation from "../wigets/withLocation"
import Auth from '../../Auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from "@material-ui/core";
import { FaThemeisle } from "react-icons/fa";

const auth = new Auth();

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user:null,
    }
  }

  async componentWillMount(){
    const user =  await auth.user();
    this.setState({user: user});
  }

  signOut = () => {
    auth.signOut()
  }


  render() {

    const {user} = this.state;
    return (
     <div>
       {
         user === null ?
         <div style={{display:'flex', backgroundColor:'white', height:'100vh', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress />
         </div>:
          <div className="search-bar-wrapper" style={{display:'flex', justifyContent:'flex-end'}}>
              <Button color="primary" 
                onClick={()=>{this.signOut()}} 
                style={{backgroundColor:'#fd7e14', color:'white', float:'right'}}
              >Sign Out</Button>
          </div>
        }
     </div>
    )
  }
}

export default withLocation(Header)
