import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../../images/orange.png"
import { Hidden, Avatar } from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';


const mainStyles = makeStyles(()=>({
  title:{
    fontSize: 30,
    color: 'white',
    fontWeight:'bold'
  },
  content:{
    width: 'calc(100% - 20px)',
    height: 'min-content',
    margin: 10,
    borderRadius: 10,
    backgroundColor:'#5d412587', 
    border: 'solid 1px #5d4125',
    paddingTop: 30,
    paddingBottom: 30,
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  avatar:{
    width: 60,
    height: 60,
  }
}))

const menuItemStyles = makeStyles(()=>({
    root:{
      width:' 100%',
      display:'flex',
      padding: '0px 30px',
      alignItems:'center',
      fontSize: 16,
      color: 'white',
      height: 50,
      borderBottom:'solid 1px #5d4125',
      cursor:'pointer'
    },
    rootSelected:{
      width:' 100%',
      display:'flex',
      padding: '0px 30px',
      alignItems:'center',
      fontSize: 16,
      color: 'white',
      height: 50,
      borderBottom:'solid 1px #5d4125',
      backgroundColor:'rgba(144, 93, 42, 0.99)',
      cursor:'pointer'
    },
    title:{
      marginLeft: 20,
    }
}))

const MenuItem = (props) =>{

  const classes = menuItemStyles();
  const {icon, title, slelected} = props;

   return (
    <div className={slelected?classes.rootSelected:classes.root}>
        {icon}
        <div className={classes.title}>{title}</div>
    </div>
  )
}



const DesktopSidebar = () => {

  const classes = mainStyles();

  const [isOpen, setIsOpen] = useState(false)
  const [menu, setMenu] = useState("dashboard")

  return (
    <>
      <div className="app-sidebar" style={{backgroundColor:'rgba(144, 93, 42, 0.99)', boxShadow:'0 0 10px 3px #000'}}>
        <div className="tab logo-wrapper">
          <Link to="/" style={{cursor:'pointer'}}>
            <img src={Logo} className="logo" alt="Cloudpin"/>{" "} 
            <Hidden mdDown> 
                <span className={classes.title}>CloudPin</span>
            </Hidden>
          </Link>
        </div>

        <div className="tab sidebar-link-wrapper" id="profile-tab">
          <div className={classes.content}>
              
              <div>
                <Avatar  className={classes.avatar}/>
                <h6>admin</h6>
              </div>
              <MenuItem icon={<DashboardIcon />} title="dashboard"  slelected/>
              <MenuItem icon={<PeopleAltIcon />} title="users" />
          </div>
        </div>
      </div>

    </>
  )
}

export default DesktopSidebar
