import React  from "react"
import Sidebar from "react-sidebar"
import { IoMdMenu } from "react-icons/io"
import Header from "./admin-header"
import DesktopSidebar from "./admin-sidebar"
import Auth from '../../Auth'

const auth = new Auth();

class AdminLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      isOpen: false,
      user:'',
    }
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }

  async componentWillMount() {

    const user = await auth.user();
    this.setState({user: user});

  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  showModal(open) {
    this.setState({isOpen: open})
  }

  hideModal() {
    this.setState({isOpen: false})
  }

  render() {
    const {children} = this.props;
    return (
      <Sidebar
        sidebar={<DesktopSidebar> </DesktopSidebar>}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      >
        <div className="sidebar-btn">
          <IoMdMenu size={24} onClick={() => this.onSetSidebarOpen(true)} />
        </div>

        <div className="d-flex">
          <div className="desktop-only">
            <DesktopSidebar> </DesktopSidebar>
          </div>
          <div className="" style={{ width: "100%" }}>
            <Header></Header>
            <div className="content-wrapper">
                {children}
            </div>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default AdminLayout
